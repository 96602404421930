.navback{
    background-color: rgb(255, 0, 0);
}

.texth{
    color: #FFBB05;
    
  }


label 
{
    float: right;
}

.btn
{
  margin-top: 7px ;
}

.rdb_span 
{
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.fld_area 
{
  margin-bottom: 7px ;
}

.fld_lbl 
{
    float: left;
    margin-bottom: 8px ;
    font-weight: 700;
}

.rslt_fld_lbl 
{
    float: right;
    margin-bottom: 3px ;
    padding-top: 5px;
}

.rslt_fld_val 
{
    padding-top: 5px;
}

.rslt_are
{
    background-color: #ffbb05;
    margin: 3px 0px;    
}

.active{
  background-color: white !important;
}

hr
{
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0;
    border-top: 1px dotted #777;    
}

.login-content {
  padding: 30px 21px;
}

.login-content hr
{
    margin-top: 3px;
    margin-bottom: 3px;
    border: 0;
    border-top: 1px dotted #777;    
}

.login-content a {
  background-color: #ffbb05;
  border: 1px solid #ffbb05;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}

.login-content a:hover {
  background-color: transparent;
  color: #333;
  border: 1px solid #ffbb05;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}

.login-content .primary-button {
  margin-top: 3px;
}

/* different text color */ 
/* required field color */
.txt_reqrd
{
    color: #ff0538;
    /* FA2602 FF0538  f10404   */
}

.wip_img
{
	width: 370px;
	height: 30px ;
}

.msg {
    padding-top: 50px;
}

.heading2{
  /* border: #333 3px solid; */
}

.heading2 h1{
  margin-top: 15px;
  font-size: 38px;
  text-transform: uppercase;
  color: #ffbb05;
  font-weight: 900;
  letter-spacing: 1px;
}
.heading2 span{
  font-size: 16px;
    text-transform: uppercase;
    color: black;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.custcol{
  margin-top: -5%;
}
.custpd{
  padding-top: 10%;
}

.content2 {
  margin-bottom: 100px;
  background-color: #fff;
  border-top: 15px solid #ffbb05;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.content3reg {
  /* margin-bottom: 100px; */
  margin-top: -80px;
  background-color: #fff;
  border-top: 15px solid #ffbb05;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.content3 {
  margin-bottom: 100px;
  margin-top: -80px;
  background-color: #fff;
  border-top: 15px solid #ffbb05;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
.first-content a {
  background-color: transparent;
  /*
  border: 1px solid #ffbb05;
  
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;*/
}

.first-content a:hover {
  background-color: transparent;
  color: transparent;
  /*
  border: 1px solid #ffbb05;
  */
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}


.fivth-slide img {
  width: 100%;
  overflow: hidden;
}

.fivth-slide .left-image {
  padding-right: 0px!important;
}

.fivth-slide .right-image {
  padding-left: 0px!important;
}

.fivth-slide .right-about-text {
  text-align: left;
  margin-left: 15px;
  margin-right: 30px;
}




@media (max-width: 850px)
{

    .rslt_fld_lbl 
    {
        float: left;
    }


}

.cd-head-login{
background-color: rgba(51,51,51);
height: 40vh;
display: flex;
width: 100%;
flex-direction: row;
}

.head{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  background-color: rgba(51, 51, 51);
  width: inherit;
  font-weight: 900;
  font-size: 38px;
}

.head h1{
  color: "#FFBB05";
  font-weight: 900;
  font-size: 38px;
}

.cd-hero-slider{
  top: -200px;
}

.cd-hero{
  /* height: 50vh; */
}
.adjustedtext{}

.regtop{
  position: relative;
  top:-200px;
  /* height: 90vh; */
}

.btn-style{
  display: inline-block;
  background-color: #ffbb05;
  color: #343434;
  font-size: 16px;
  padding: 9px 12px 7px 12px;
  border-radius: 7px;
  text-decoration: none;
  outline: none;
  margin-bottom: 0px;
  min-width: 120px;
}

.row-custom{
  display: flex;
  justify-content: center;
}
.form-custom{
  width: 100%;
  align-items: center;
    display: flex;
    flex-direction: column;
}

.size{
  width:85%;
  margin-bottom: 10px;
}

.login-custom{
  width: 85%;
}

.custom-label
{
  text-align: center;
  display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 50px;
}

.custom-label-login
{
  text-align: center;
  display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.custom-button{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-button{
  text-align: right;
  display: flex;
  justify-content: center;
}

.qrsize{
  /* height: 100%; */
  width: 100%;
}

.full{
  position: relative;
  top: -160px;
}
.about-row{
  margin-bottom: 10px;
}

