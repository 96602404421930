@media only screen and (max-width:320px) {}

@media only screen and (max-width:420px) {
    /* .head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: white;
        width: inherit;
        font-weight: 900;
        font-size: 38px;
    } */

    .mainfull {
        height: 100%;
    }

    /* Login  */
    .cd-head-login {
        background-color: rgba(51, 51, 51);
        height: 30vh;
        display: flex;
        width: 100%;
        flex-direction: row;
    }

    .heading {
        text-align: center;
        margin-bottom: 20%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .heading2 {
        text-align: center;
        /* margin-bottom: 20%; */
        margin-left: 10%;
        margin-right: 10%;
    }

    .heading2 h1 {
        /* margin-top: 60px; */
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .heading span {
        font-size: 13px;
        /* letter-spacing: px; */
    }


    /* Body  */
    .cd-hero {
        height: 60%;
    }

    .custom-label {
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 10px;
        padding-right: 50px;
    }

    /* Footer  */

    .footer {
        background-color: #333;
        border-top: 7px solid #ffbb05;
        position: relative;
        width: 100%;
        bottom: 0;
        display: flex;
        justify-content: center;
        z-index: 2;
        margin-top: -1px;
        position: fixed;
        left: 0;
        bottom: 0;
    }

    .footer p {
        font-size: 9px;
        color: #747474;
        margin: 0px;
        width: 60%;
        line-height: 2;
        padding: 10px 0px;
        text-align: center;
    }
}

@media only screen and (max-width:600px) {

    .cd-head-login {
        background-color: rgba(51, 51, 51);
        height: 30vh;
        display: flex;
        width: 100%;
        flex-direction: row;
    }

    .heading {
        text-align: center;
        margin-bottom: 20%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .heading2 {
        text-align: center;
        /* margin-bottom: 20%; */
        margin-left: 10%;
        margin-right: 10%;
    }

    .cd-hero {
        height: 60%;
    }

    .custom-label {
        text-align: left;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 10px;
        padding-right: 50px;
    }

    .footer {
        background-color: #333;
        border-top: 7px solid #ffbb05;
        position: relative;
        width: 100%;
        bottom: 0;
        display: flex;
        justify-content: center;
        z-index: 2;
        margin-top: -1px;
        position: fixed;
        left: 0;
        bottom: 0;
    }

    .footer p {
        font-size: 9px;
        color: #747474;
        margin: 0px;
        width: 60%;
        line-height: 2;
        padding: 10px 0px;
        text-align: center;
    }

    /* Register */
    .cd-hero-reg {
        height: 100%;
    }

    .cd-slider-nav {
        background-color: rgba(51, 51, 51);
        width: 100%;
        height: 30vh;
        margin: 0 auto;
        text-align: center !important;
        /* margin-top: 10%; */
    }

    .cd-slider-nav nav {
        height: 100%;
        padding-top: 10%;
    }

    .cd-slider-nav ul {
        border-bottom: 3px solid #fff;
        padding-bottom: 10px;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
    }

    .cd-slider-nav .image-icon {
        margin: 0 auto;
        margin-bottom: 15px;
        display: block;
        width: 80px;
        height: 80px;
        line-height: 80px;
        border-radius: 50%;
        text-align: center;
        margin: 0px;
        padding: 0px;
        /* color: white; */
        background-color: #ffbb05;
    }

    /* Usage  */

    .third-content .right-feature-text h4 {
        font-size: 14px !important;
        /* color: #121212;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top: 30px;
        margin-bottom: 30px; */
    }

    .fld_lbl {
        float: left;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 13px;
    }

    .custtext {
        font-size: 12px;
    }

    /* Navbar */

    /* .active{
       border-bottom: #ffbb05 5px solid;
    } */

    .cd-slider-nav ul .selected .active img {
        border-bottom: #ffbb05 5px solid;
        padding-bottom: 10px;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
    }

    .heading2 h1 {
        /* margin-top: 60px; */
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .heading span {
        font-size: 13px;
        /* letter-spacing: px; */
    }

    .custcol {
        margin-top: -20%;
    }

}

@media only screen and (max-width:768px) {

    .third-content .right-feature-text h4 {
        font-size: 14px !important;
        /* color: #121212;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top: 30px;
        margin-bottom: 30px; */
    }
}


@media only screen and (min-width:992px) {}

@media only screen and (min-width:1200px) {}